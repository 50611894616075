import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notification-content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }
const _hoisted_8 = { key: 6 }
const _hoisted_9 = { key: 7 }
const _hoisted_10 = { key: 8 }
const _hoisted_11 = { key: 9 }
const _hoisted_12 = { key: 10 }
const _hoisted_13 = { key: 11 }
const _hoisted_14 = { key: 12 }
const _hoisted_15 = { key: 13 }
const _hoisted_16 = { key: 14 }
const _hoisted_17 = { key: 15 }
const _hoisted_18 = { key: 16 }
const _hoisted_19 = { key: 17 }
const _hoisted_20 = { key: 18 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 19 }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { key: 20 }
const _hoisted_27 = { key: 21 }
const _hoisted_28 = { key: 22 }
const _hoisted_29 = ["innerHTML"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = ["href"]
const _hoisted_32 = { class: "gradient-button" }
const _hoisted_33 = { key: 23 }
const _hoisted_34 = { key: 24 }
const _hoisted_35 = { key: 25 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.n)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t("Unknown notification.")), 1)
        ]))
      : (_ctx.n.type === 'project_registered_in_scheme')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.$t("A new project, with name")) + " ", 1),
              _createVNode(_component_router_link, {
                to: { name: 'project', params: { id: _ctx.n.projectId } },
                class: "projectName"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createTextVNode(" " + _toDisplayString(_ctx.$t("was registered as a candidate for the methodology")) + " ", 1),
              _createVNode(_component_router_link, {
                to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]))
        : (_ctx.n.type === 'project_approved_in_scheme')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("p", null, [
                _createTextVNode(_toDisplayString(_ctx.$t("The project")) + " ", 1),
                _createVNode(_component_router_link, {
                  to: { name: 'project', params: { id: _ctx.n.projectId } },
                  class: "projectName"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                  ]),
                  _: 1
                }, 8, ["to"]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("was approved for the methodology")) + " ", 1),
                _createVNode(_component_router_link, {
                  to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]))
          : (_ctx.n.type === 'project_unapproved_in_scheme')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("The project")) + " ", 1),
                  _createVNode(_component_router_link, {
                    to: { name: 'project', params: { id: _ctx.n.projectId } },
                    class: "projectName"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("had its approval revoked for the methodology")) + " ", 1),
                  _createVNode(_component_router_link, {
                    to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ])
              ]))
            : (_ctx.n.type === 'body_registered_in_scheme')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t("A new certification body, with name")) + " ", 1),
                    _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("was registered as a candidate for the methodology")) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ])
                ]))
              : (_ctx.n.type === 'body_accredited_in_scheme')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("p", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t("The certification body")) + " ", 1),
                      _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("was accredited for the methodology")) + " ", 1),
                      _createVNode(_component_router_link, {
                        to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]))
                : (_ctx.n.type === 'body_unaccredited_in_scheme')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("p", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t("The certification body")) + " ", 1),
                        _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("had its accreditation revoked for the methodology")) + " ", 1),
                        _createVNode(_component_router_link, {
                          to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ])
                    ]))
                  : (_ctx.n.type === 'auditory_created')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("p", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t("The certification body")) + " ", 1),
                          _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("created the auditory")) + " ", 1),
                          _createVNode(_component_router_link, {
                            to: { name: 'auditory', params: { id: _ctx.n.bodyId, index: _ctx.n.auditoryIndex + '' } }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("#" + _toDisplayString(_ctx.n.auditoryIndex), 1)
                            ]),
                            _: 1
                          }, 8, ["to"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("with date")) + " " + _toDisplayString(_ctx.renderDate(_ctx.n.auditoryDate)) + " " + _toDisplayString(_ctx.$t("for project")) + " ", 1),
                          _createVNode(_component_router_link, {
                            to: { name: 'project', params: { id: _ctx.n.projectId } },
                            class: "projectName"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                            ]),
                            _: 1
                          }, 8, ["to"]),
                          _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the methodology")) + " ", 1),
                          _createVNode(_component_router_link, {
                            to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ])
                      ]))
                    : (_ctx.n.type === 'auditory_status_changed')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t("The auditory")) + " ", 1),
                            _createVNode(_component_router_link, {
                              to: { name: 'auditory', params: { id: _ctx.n.bodyId, index: _ctx.n.auditoryIndex + '' } }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("#" + _toDisplayString(_ctx.n.auditoryIndex), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            _createTextVNode(" , " + _toDisplayString(_ctx.$t("created by")) + " ", 1),
                            _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("for project")) + " ", 1),
                            _createVNode(_component_router_link, {
                              to: { name: 'project', params: { id: _ctx.n.projectId } },
                              class: "projectName"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the methodology")) + " ", 1),
                            _createVNode(_component_router_link, {
                              to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                              ]),
                              _: 1
                            }, 8, ["to"]),
                            _createTextVNode(" , " + _toDisplayString(_ctx.$t("changed its status to")) + " ", 1),
                            _createElementVNode("b", null, _toDisplayString(_ctx.renderAuditoryStatus(_ctx.n.auditoryStatus)), 1)
                          ])
                        ]))
                      : (_ctx.n.type === 'carbon_credit_created')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("p", null, [
                              _createTextVNode(_toDisplayString(_ctx.$t("A new carbon credit")) + " ", 1),
                              _createVNode(_component_router_link, {
                                to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                ]),
                                _: 1
                              }, 8, ["to"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("was created with an amount of")) + " ", 1),
                              _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("by project")) + " ", 1),
                              _createVNode(_component_router_link, {
                                to: { name: 'project', params: { id: _ctx.n.projectId } },
                                class: "projectName"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                                ]),
                                _: 1
                              }, 8, ["to"]),
                              _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the methodology")) + " ", 1),
                              _createVNode(_component_router_link, {
                                to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                                ]),
                                _: 1
                              }, 8, ["to"])
                            ])
                          ]))
                        : (_ctx.n.type === 'carbon_credit_certified')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("p", null, [
                                _createTextVNode(_toDisplayString(_ctx.$t("The carbon credit")) + " ", 1),
                                _createVNode(_component_router_link, {
                                  to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t("was certified by")) + " ", 1),
                                _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                                _createTextVNode(" , " + _toDisplayString(_ctx.$t("for project")) + " ", 1),
                                _createVNode(_component_router_link, {
                                  to: { name: 'project', params: { id: _ctx.n.projectId } },
                                  class: "projectName"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"]),
                                _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the methodology")) + " ", 1),
                                _createVNode(_component_router_link, {
                                  to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"])
                              ])
                            ]))
                          : (_ctx.n.type === 'carbon_credit_not_certified')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("p", null, [
                                  _createTextVNode(_toDisplayString(_ctx.$t("The carbon credit")) + " ", 1),
                                  _createVNode(_component_router_link, {
                                    to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["to"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t("was not certified by")) + " ", 1),
                                  _createElementVNode("b", null, _toDisplayString(_ctx.n.bodyName), 1),
                                  _createTextVNode(" , " + _toDisplayString(_ctx.$t("for project")) + " ", 1),
                                  _createVNode(_component_router_link, {
                                    to: { name: 'project', params: { id: _ctx.n.projectId } },
                                    class: "projectName"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.truncatedProjectName), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["to"]),
                                  _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the methodology")) + " ", 1),
                                  _createVNode(_component_router_link, {
                                    to: { name: 'methodologies-detail', params: { id: _ctx.n.schemeId } }
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.n.schemeName), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["to"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t("for this reason")) + ": " + _toDisplayString(_ctx.n.reason), 1)
                                ])
                              ]))
                            : (_ctx.n.type === 'carbon_credit_listed')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createElementVNode("p", null, [
                                    _createTextVNode(_toDisplayString(_ctx.$t("The carbon credit")) + " ", 1),
                                    _createVNode(_component_router_link, {
                                      to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["to"]),
                                    _createTextVNode(" , " + _toDisplayString(_ctx.$t("with an amount of")) + " ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                    _createTextVNode(" , " + _toDisplayString(_ctx.$t("was listed in the marketplace for the price")) + ": ", 1),
                                    _createElementVNode("b", null, _toDisplayString(_ctx.n.priceText), 1)
                                  ])
                                ]))
                              : (_ctx.n.type === 'carbon_credit_delisted')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("p", null, [
                                      _createTextVNode(_toDisplayString(_ctx.$t("The carbon credit")) + " ", 1),
                                      _createVNode(_component_router_link, {
                                        to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["to"]),
                                      _createTextVNode(" , " + _toDisplayString(_ctx.$t("was removed from the marketplace for the reason")) + ": " + _toDisplayString(_ctx.n.reason), 1)
                                    ])
                                  ]))
                                : (_ctx.n.type === 'carbon_credit_sold')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                      _createElementVNode("p", null, [
                                        _createTextVNode(_toDisplayString(_ctx.$t("The carbon credit")) + " ", 1),
                                        _createVNode(_component_router_link, {
                                          to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                          ]),
                                          _: 1
                                        }, 8, ["to"]),
                                        _createTextVNode(" , " + _toDisplayString(_ctx.$t("with an amount of")) + " ", 1),
                                        _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                        _createTextVNode(" , " + _toDisplayString(_ctx.$t("was sold for")) + " ", 1),
                                        _createElementVNode("b", null, _toDisplayString(_ctx.n.priceText), 1),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("to")) + " ", 1),
                                        _createElementVNode("b", null, _toDisplayString(_ctx.n.owner), 1)
                                      ])
                                    ]))
                                  : (_ctx.n.type === 'carbon_credit_bought')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                        _createElementVNode("p", null, [
                                          _createTextVNode(_toDisplayString(_ctx.$t("You bought the carbon credit")) + " ", 1),
                                          _createVNode(_component_router_link, {
                                            to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["to"]),
                                          _createTextVNode(" , " + _toDisplayString(_ctx.$t("with an amount of")) + " ", 1),
                                          _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                          _createTextVNode(" , " + _toDisplayString(_ctx.$t("for")) + " ", 1),
                                          _createElementVNode("b", null, _toDisplayString(_ctx.n.priceText), 1),
                                          _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the wallet with address")) + " ", 1),
                                          _createElementVNode("b", null, _toDisplayString(_ctx.n.owner), 1)
                                        ])
                                      ]))
                                    : (_ctx.n.type === 'carbon_credit_retired')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                          _createElementVNode("p", null, [
                                            _createTextVNode(_toDisplayString(_ctx.$t("You retired the carbon credit")) + " ", 1),
                                            _createVNode(_component_router_link, {
                                              to: { name: 'carbon-credit', params: { id: _ctx.n.creditId } }
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("#" + _toDisplayString(_ctx.n.creditId), 1)
                                              ]),
                                              _: 1
                                            }, 8, ["to"]),
                                            _createTextVNode(" , " + _toDisplayString(_ctx.$t("with an amount of")) + " ", 1),
                                            _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                            _createTextVNode(" , " + _toDisplayString(_ctx.$t("using the wallet with address")) + " ", 1),
                                            _createElementVNode("b", null, _toDisplayString(_ctx.n.owner), 1)
                                          ])
                                        ]))
                                      : (_ctx.n.type === 'carbon_credit_retired_all')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("You have retired all your carbon credits.")), 1)
                                          ]))
                                        : (_ctx.n.type === 'token_transaction_in')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                              _createElementVNode("p", null, [
                                                _createTextVNode(_toDisplayString(_ctx.$t("You received")) + " ", 1),
                                                _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                                _createTextVNode(" , " + _toDisplayString(_ctx.$t("for your wallet")) + " ", 1),
                                                _createElementVNode("b", null, _toDisplayString(_ctx.n.walletAddress), 1),
                                                _createTextVNode(". "),
                                                (_ctx.n.from)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                                                      _createTextVNode("(" + _toDisplayString(_ctx.$t("From wallet")) + ": ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.from), 1),
                                                      _createTextVNode(")")
                                                    ]))
                                                  : (_openBlock(), _createElementBlock("span", _hoisted_22, "(" + _toDisplayString(_ctx.$t("Purchased funds")) + ")", 1))
                                              ])
                                            ]))
                                          : (_ctx.n.type === 'token_transaction_out')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                (_ctx.n.to)
                                                  ? (_openBlock(), _createElementBlock("p", _hoisted_24, [
                                                      _createTextVNode(_toDisplayString(_ctx.$t("You sent")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.$t("to")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.to), 1),
                                                      _createTextVNode(" , " + _toDisplayString(_ctx.$t("from your wallet")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.walletAddress), 1)
                                                    ]))
                                                  : (_openBlock(), _createElementBlock("p", _hoisted_25, [
                                                      _createTextVNode(_toDisplayString(_ctx.$t("You withdrew")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.$t("from your wallet")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.walletAddress), 1)
                                                    ]))
                                              ]))
                                            : (_ctx.n.type === 'penalty_applied')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                                  _createElementVNode("p", null, [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("A new penalty")) + " ", 1),
                                                    _createElementVNode("b", null, _toDisplayString(_ctx.n.penaltyId), 1),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("has been applied for entity")) + " ", 1),
                                                    _createElementVNode("b", null, _toDisplayString(_ctx.n.entityName), 1),
                                                    _createTextVNode(" ("),
                                                    _createElementVNode("i", null, _toDisplayString(_ctx.n.entityId), 1),
                                                    _createTextVNode(") , " + _toDisplayString(_ctx.$t("with amount")) + " ", 1),
                                                    _createElementVNode("b", null, _toDisplayString(_ctx.n.amountText), 1),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("and reason")) + ": " + _toDisplayString(_ctx.n.reason), 1)
                                                  ])
                                                ]))
                                              : (_ctx.n.type === 'penalty_cleared')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                                    _createElementVNode("p", null, [
                                                      _createTextVNode(_toDisplayString(_ctx.$t("The penalty")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.penaltyId), 1),
                                                      _createTextVNode(" , " + _toDisplayString(_ctx.$t("applied for entity")) + " ", 1),
                                                      _createElementVNode("b", null, _toDisplayString(_ctx.n.entityName), 1),
                                                      _createTextVNode(" ("),
                                                      _createElementVNode("i", null, _toDisplayString(_ctx.n.entityId), 1),
                                                      _createTextVNode(") , " + _toDisplayString(_ctx.$t("has been cleared")), 1)
                                                    ])
                                                  ]))
                                                : (_ctx.n.type === 'admin_request_role')
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                                      _createElementVNode("p", null, [
                                                        _createTextVNode(_toDisplayString(_ctx.$t("The user")) + " ", 1),
                                                        _createVNode(_component_router_link, {
                                                          to: { name: 'admin-users-single', params: { id: _ctx.n.uid } }
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createElementVNode("b", null, _toDisplayString(_ctx.n.username), 1),
                                                            _createTextVNode(" ("),
                                                            _createElementVNode("i", null, _toDisplayString(_ctx.n.uid), 1),
                                                            _createTextVNode(")")
                                                          ]),
                                                          _: 1
                                                        }, 8, ["to"]),
                                                        _createTextVNode(" , " + _toDisplayString(_ctx.$t("with email")) + " ", 1),
                                                        _createElementVNode("b", null, _toDisplayString(_ctx.n.email), 1),
                                                        _createTextVNode(" , " + _toDisplayString(_ctx.$t("requested the role")) + " ", 1),
                                                        _createElementVNode("b", null, _toDisplayString(_ctx.n.role), 1)
                                                      ]),
                                                      _createElementVNode("p", {
                                                        innerHTML: _ctx.renderMessage((_ctx.n.message || '') + '')
                                                      }, null, 8, _hoisted_29),
                                                      (_ctx.n.document && _ctx.n.documentName)
                                                        ? (_openBlock(), _createElementBlock("p", _hoisted_30, [
                                                            _createTextVNode(_toDisplayString(_ctx.$t("Document")) + ": ", 1),
                                                            _createElementVNode("a", {
                                                              href: _ctx.getNotificationDocument(_ctx.id, _ctx.n.documentName),
                                                              target: "_blank",
                                                              rel: "noopener noreferrer"
                                                            }, _toDisplayString(_ctx.n.documentName), 9, _hoisted_31)
                                                          ]))
                                                        : _createCommentVNode("", true),
                                                      _createElementVNode("p", null, [
                                                        (!_ctx.n.handled)
                                                          ? (_openBlock(), _createBlock(_component_router_link, {
                                                              key: 0,
                                                              class: "link-request",
                                                              to: { name: 'accept-rol-request', params: { id: _ctx.id } }
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createElementVNode("button", _hoisted_32, _toDisplayString(_ctx.$t("Accept request")), 1)
                                                              ]),
                                                              _: 1
                                                            }, 8, ["to"]))
                                                          : _createCommentVNode("", true)
                                                      ])
                                                    ]))
                                                  : (_ctx.n.type === 'decline_request_role')
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                                        _createElementVNode("p", null, [
                                                          _createTextVNode(_toDisplayString(_ctx.$t("Your role request has been declined for this reason")) + ": ", 1),
                                                          _createElementVNode("b", null, _toDisplayString(_ctx.n.reason), 1)
                                                        ])
                                                      ]))
                                                    : (_ctx.n.type === 'accept_request_role')
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                                          _createElementVNode("p", null, _toDisplayString(_ctx.$t("Your role request has been accepted")), 1)
                                                        ]))
                                                      : (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                                          _createElementVNode("p", null, _toDisplayString(_ctx.$t("Unknown notification.")), 1)
                                                        ]))
  ]))
}