// Routes

"use strict";

import { createRouter, createWebHashHistory, Router } from "vue-router";

// Router
// https://router.vuejs.org/guide/#javascript

import { defineAsyncComponent } from "vue";
import ComponentLoader from "@/components/utils/ComponentLoader.vue";

const ASYNC_LOADER_DELAY = 200;

const routes = [
    {
        name: "home",
        path: '/',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/HomePage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },


    {
        name: "about",
        path: '/about',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/AboutPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },

    {
        name: "login",
        path: '/login',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/LoginPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },
    {
        name: "tfa-login",
        path: '/login/tfa',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/TwoFactorLoginPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },

    {
        name: "signup",
        path: '/signup',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/SignupPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },
    {
        name: "signup-success",
        path: '/signup/success',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/SignupSuccessPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },

    {
        name: "forgot-password",
        path: '/password/forgot',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/ForgotPasswordPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },
    {
        name: "reset-password",
        path: '/password/reset/:uid/:token',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/ResetPasswordPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },

    {
        name: "verify-email",
        path: '/email/verify/:uid/:token',
        component: defineAsyncComponent({
            loader: () => import("@/components/routes/EmailVerifyPage.vue"),
            loadingComponent: ComponentLoader,
            delay: ASYNC_LOADER_DELAY,
        }),
    },

    {
        name: "profile",
        path: '/profile',
        component: () => import("@/components/routes/ProfilePage.vue"),
    },

    {
        name: "footprint",
        path: '/footprint',
        component: () => import("@/components/routes/FootprintHistory.vue"),
    },

    {
        name: "admin-users",
        path: '/admin/users',
        component: () => import("@/components/routes/admin/UsersAdministration.vue"),
    },

    {
        name: "admin-users-single",
        path: '/admin/users/:id',
        component: () => import("@/components/routes/admin/UsersAdministrationSingle.vue"),
    },

    {
        name: "accept-rol-request",
        path: '/admin/users/:id/accept-rol-request',
        component: () => import("@/components/routes/admin/UsersAdministrationAcceptRequest.vue"),
    },

    {
        name: "projects",
        path: '/projects',
        component: () => import("@/components/routes/projects/ProjectsPage.vue"),
    },

    {
        name: "project",
        path: '/projects/:id',
        component: () => import("@/components/routes/projects/ProjectPage.vue"),
    },

    {
        name: "pending-methodologies",
        path: '/pending-methodologies',
        component: () => import("@/components/routes/methodologies/MethodologiesPendingPage.vue"),
    },

    {
        name: "allowed-methodologies",
        path: '/allowed-methodologies',
        component: () => import("@/components/routes/methodologies/MethodologiesAllowedPage.vue"),
    },

    {
        name: "token-transactions",
        path: '/token-transactions',
        component: () => import("@/components/routes/tokens-information/TokenTransactionPage.vue"),
    },

    {
        name: "token-information",
        path: '/token-information',
        component: () => import("@/components/routes/tokens-information/TokenInformationPage.vue"),
    },


    {
        name: "certification-bodies-projects",
        path: '/certification-bodies-projects',
        component: () => import("@/components/routes/certification-bodies/CertificationBodyProjectsPage.vue"),
    },

    {
        name: "project-auditories",
        path: '/certification-bodies-projects/:id/auditories',
        component: () => import("@/components/routes/certification-bodies/CertificationBodyAuditsPage.vue"),
    },


    {
        name: "certification-schemes",
        path: '/certification-schemes',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemesPage.vue"),
    },

    {
        name: "certification-scheme",
        path: '/certification-schemes/:id',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemePage.vue"),
    },

    {
        name: "certification-scheme-projects-pending",
        path: '/certification-schemes/projects-pending',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemeProjectsPendingPage.vue"),
    },
    {
        name: "certification-scheme-bodies-pending",
        path: '/certification-schemes/bodies-pending',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemeBodiesAccreditPage.vue"),
    },

    {
        name: "certification-scheme-projects-verified",
        path: '/certification-schemes/projects-verified',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemeProjectsVerifiedPage.vue"),
    },

    {
        name: "registered-projects",
        path: '/certification-schemes/:id/projects',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemePage.vue"),
    },

    {
        name: "registered-certification-bodies",
        path: '/certification-schemes/:id/certification-bodies',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemePage.vue"),
    },

    {
        name: "standards",
        path: '/certification-schemes/:id/standards',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemePage.vue"),
    },

    {
        name: "standard",
        path: '/certification-schemes/:id/standards/:index',
        component: () => import("@/components/routes/certification-schemes/CertificationSchemePage.vue"),
    },

    {
        name: "verify-profile",
        path: '/verify-profile/:mode',
        component: () => import("@/components/routes/verify-profile/VerifyProfilePage.vue"),
    },

    {
        name: "penalties",
        path: '/penalties',
        component: () => import("@/components/routes/penalty-registry/PenaltyRegistriesPage.vue"),
    },

    {
        name: "registry",
        path: '/central-offset-registry',
        component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryPage.vue"),
    },

    // {
    //     name: "my-registry",
    //     path: '/my-credits',
    //     component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryMyCreditsPage.vue"),
    // },

    {
        name: "allowed-schemes",
        path: '/central-offset-registry/allowed-schemes',
        component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryPage.vue"),
    },

    {
        name: "transactions",
        path: '/central-offset-registry/transactions',
        component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryPage.vue"),
    },

    {
        name: "carbon-credits",
        path: '/central-offset-registry/carbon-credits',
        component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryPage.vue"),
    },

    {
        name: "carbon-credit",
        path: '/central-offset-registry/carbon-credits/:id',
        component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryCarbonCreditPage.vue"),
    },

    {
        name: "co2-calculator",
        path: '/co2-calculator',
        component: () => import("@/components/routes/calculator/CO2CalculatorPage.vue"),
    },


    {
        name: "methodologies",
        path: '/methodologies',
        component: () => import("@/components/routes/methodologies/MethodologiesPage.vue"),
    },
    {
        name: "methodologies-detail",
        path: '/methodologies-detail/:id',
        component: () => import("@/components/routes/methodologies/MethodologiesDetailPage.vue"),
    },
    {
        name: "central-offset-registry",
        path: '/central-offset-registry',
        component: () => import("@/components/routes/central-offset-registry/CentralOffsetRegistryPage.vue"),
    },

    {
        name: "traceability",
        path: '/traceability',
        component: () => import("@/components/routes/TraceabilityPage.vue"),
    },

    {
        name: "organization",
        path: '/organization/:id',
        component: () => import("@/components/routes/organization/OrganizationPage.vue"),
    },

    {
        name: "organization-invites",
        path: '/organization-invites',
        component: () => import("@/components/routes/organization/InvitesPage.vue"),
    },

    {
        name: "notifications",
        path: '/notifications',
        component: () => import("@/components/routes/NotificationsPage.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHashHistory(),
        routes, // short for `routes: routes`
    })

    return router;
}
